<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12 my-4>
                    <v-text-field :label="$t('Checkin')" v-model="item.checkin" disabled />
                </v-flex>

                <v-flex xs12>
                    <v-autocomplete v-model="item.store" :items="stores" item-value="id" item-text="name" :label="$t('Lojas')" />
                </v-flex>

                <v-flex xs12 my-4>
                    <v-text-field :label="$t('Digite código da AT')" v-model="item.code" v-mask="rules.alphanumericMask" :rules="[rules.required, rules.alphanumeric]" />
                </v-flex>

                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                user: null,
                store: null,
            },
            item: { ...this.itemClean },
            stores: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        getStores() {
            this.$http
                .get('stores?enable=true')
                .then((result) => {
                    if (result) {
                        this.stores = result;
                    }
                })
                .catch(() => {
                    this.stores = null;
                });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        this.getStores();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>